<template>
  <el-dialog
      title="查看条形码"
      :visible.sync="value"
      width="30%"
      :before-close="handleClose">
    <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>

export default {
  props:{
    value:{
      type:Boolean
    },
    fbaPdfUrl:{
      type:String
    }
  },
  name: "viewTags",
  data: () => ({}),

  mounted() {

  },
  methods: {
    handleClose(){
      this.$emit('input',false)
    }
  },
  watch: {
    fbaPdfUrl:{
      handler(){
        console.log(this.fbaPdfUrl)
      }
    }
  },

}
</script>

<style scoped lang="less">

</style>