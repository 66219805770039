<template>
  <el-dialog
      title="订单备注信息"
      :visible.sync="value"
      width="30%"
      @open="open"
      :before-close="handleClose">
      <el-form :model="form" :rules="rules" ref="ruleForm" >
          <el-form-item label="备注" prop="text">
            <el-input type="textarea" v-model="form.text" :disabled="Number(freightPayStatus) === 90 || Number(freightPayStatus) === 100 || Number(freightPayStatus) === 80"></el-input>
          </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer" v-show="!(Number(freightPayStatus) === 90 || Number(freightPayStatus) === 100 || Number(freightPayStatus) === 80)">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="determine">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import {repairNotes} from "@/api/order";

export default {
  name: "remarks",
  props:{
    value:{
      type:Boolean,
      default:false
    },
    fbaNumber:{
      type:String,
      default: null
    },
    remarkText:{
      type:String,
      default: null
    },
    freightPayStatus:{
      type:Number,
      default: null
    }
  },
  data: () => ({
    form:{
      text:''
    },
    rules:{
      text:[
        { required: true, message: '请输入订单备注信息', trigger: 'blur' },
      ]
    }
  }),
  mounted() {

  },
  methods: {
    handleClose(){
      this.$emit('input',false)
      this.$emit('close',null)
      if( Number(this.freightPayStatus) !== 90 || Number(this.freightPayStatus) !== 100 || Number(this.freightPayStatus) !== 80) {
        this.$refs.ruleForm.resetFields();
      }

    },
    determine(){
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            const {msg}  = await repairNotes({
              fbaNumber:this.fbaNumber,
              remark:this.form.text
            })
            this.$message.success(msg)
            this.handleClose()
            this.$emit('update')
          }catch (e) {
            this.$message.error(e?.msg)
          }
        } else {
          return false;
        }
      });
    },
    open() {
      this.form.text = this.remarkText
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">
/deep/.el-dialog__header {
  border-bottom: 1px solid #eee;
}
/deep/.el-dialog__body {
  padding: 20px 20px 0 ;
}

</style>