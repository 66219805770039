<template>
  <el-dialog
      :title="tagTitle"
      :visible.sync="value"
      width="700px"
      :before-close="handleClose"
      @open="open"
  >


    <div class="scroll_box">
      <div v-for="item in labelList" :key="item.id" class="mb-10">
        <div class="boxNumber">
          <div>盒子编号：{{item.code}}</div>
          <div>{{item.length + '*' + item.width + '*' + item.height}} </div>
        </div>
        <div class="upload_box">
          <div class="commodityInformation">商品信息：</div>
          <div  class="commodityInformation_box" v-for="(val,index) in item.goods " :key="index">
            <span >{{val.GoodsLabel}}</span>
            <span  class="m_10">卖家SKU：{{val.SellerSKU}}</span>
            <span>FNSKU：{{val.FNSKU}}</span>
            <span class="m_10">数量：{{val.Quantity}}</span>
          </div>


          <div class="upload_content" v-if="tagTitle === '上传标签'">
            <el-upload
                ref="upload"
                class="upload-demo"
                action="/"
                :multiple="false"
                :limit="1"
                :before-upload="beforeAvatarUpload"
                :show-file-list="false"
            >
              <div class="fileName">{{item.fileName}}</div>
              <el-button size="small" type="primary" @click="upload(item.id)">上传</el-button>
            </el-upload>
          </div>

          <div v-else class="viewTags">

            <el-button size="small" type="primary" @click="viewTags(item.fba_cartonLabel_url)">查看</el-button>
          </div>
        </div>
      </div>
    </div>





     <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirmUpload">确 定</el-button>
     </span>
  </el-dialog>
</template>

<script>


import {getBoxLabel , uploadBoxLabel} from "@/api/order";

export default {
  name: "uploadTag",
  props:{
    value:{
      type:Boolean,
      default:false
    },
    fbaNumber:{
      type:String,
      default: null
    },
    tagTitle:{
      type:String,
    }
  },
  data: () => ({
    labelList:[],//标签list
    fileId:null,
    boxLabelFileArr:[]
  }),

  mounted() {
  },
  methods: {
    //查看标签
    viewTags(url){
      window.open(url)
    },


    //确定上传
    async confirmUpload(){
    const flag = this.boxLabelFileArr.some(item=>{
      console.log(item)
      return item.file === null
    })
      if(!flag && this.boxLabelFileArr.length !== 0) {
        try {
          const {data,msg} = await uploadBoxLabel({
            fbaNumber:this.fbaNumber,
            boxLabelFileArr:this.boxLabelFileArr
          })
          this.$message.success(msg)
          this.handleClose()
          this.$emit('update')
        }catch (e) {
          this.$message.error(e?.msg)
        }
      } else {
        this.$message.error('请上传pdf文件')
      }


    },


    upload(e){
      this.fileId = e
    },



    //图片上传前
    beforeAvatarUpload(file){
      // console.log(file)


      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        this.$message.error('只能是 PDF 格式!');
        return isPDF
      }


      const { name } = file
      this.boxLabelFileArr = []
      this.labelList.forEach(item=>{
        if(item.id === this.fileId) {
          item.fileName = name
          item.file = file
        }
        this.boxLabelFileArr.push({
          code:item.code,
          file:item.file
        })
      })
      console.log(this.boxLabelFileArr)
      return Promise.reject()
    },
    handleClose(){
      this.$emit('input',false)
      this.$emit('close',null)
    },
    //获取上传标签
    async open(){
      try {
        const { data } = await getBoxLabel({
          fbaNumber:this.fbaNumber
        })
        console.log(data)
        this.labelList = data.map(val=>{
          val.fileName = null
          val.file = null
          return val
        })
      }catch (e) {
        this.$message.error(e?.msg)
      }
    }
  },
  watch: {},
}
</script>

<style scoped lang="less">
.mb-10 {
  margin-bottom: 10px;
}
/deep/.el-dialog__body {
  padding: 10px 20px;
}

.scroll_box {
  height: 450px;
  overflow: auto;
  .upload_box {
    width: 650px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    padding: 10px 15px;
    border-top: 0;
    .commodityInformation {
      font-size: 14px;
      color: #333333;
    }
    .commodityInformation_box {
      margin-top: 5px;
      border-bottom: 1px solid #F7F7F7;
      padding-bottom: 5px;
      .m_10 {
        margin: 0 20px;
      }
    }
    .viewTags {
      display: flex;
      margin-top: 10px;
      justify-content: flex-end;
    }
    .upload_content {
      position: relative;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }
  .boxNumber {
    display: flex;
    justify-content: space-between;
    width: 650px;
    height: 30px;
    background: #F7F7F7;
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    line-height: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
  /deep/.el-upload--text {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .upload-demo {
    width: 100%;
    display: flex;
  }
  .fileName {
    font-size: 14px;
    width: 250px;
    line-height: 30px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    display: flex;
    justify-content: flex-start;
    &:hover {
      color:#FA4A14;
    }
  }
}
::-webkit-scrollbar {
  width: 8px; /*对垂直流动条有效*/
  height: 8px; /*对水平流动条有效*/
}
/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
  border-radius: 7px;
  background-color: #E8E8E8;
}
</style>