<template>
  <div>
<!--    //搜索-->
    <el-form>
      <el-row :gutter="30">
        <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="5">
          <el-form-item label="店铺">
            <el-select v-model="shop" placeholder="请选择活动区域" size="mini" clearable>
              <el-option v-for="item in shopList" :label="item.name" :value="item.code" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="7">
          <el-form-item label="创建日期">
            <el-date-picker
                v-model="creationTime"
                type="datetimerange"
                :picker-options="pickerOptions"
                size="mini"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="7">
          <el-form-item label="支付日期">
            <el-date-picker
                v-model="paymentTime"
                type="datetimerange"
                :picker-options="pickerOptions"
                size="mini"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="5">
          <el-form-item>
            <el-button type="primary"  @click="onSubmit" size="mini">搜索</el-button>
            <el-button class="reset" @click="onReset" size="mini">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

<!--    订单状态-->
     <div class="order_status"
          v-loading="loadingStatus"
          element-loading-spinner="el-icon-loading"
          element-loading-background="#fff"
     >
       <div
           class="order_name"
           :class="{
         'order_status_name':orderNameIndex === index,
           ['tab_item_' + index]: true,
          'tab_item': true,
       }"
           @click="orderName(index,item.id)"
           v-for="(item,index) in orderListStatus" :key="item.id"
       >
         <span>{{item.title}}</span>
         <span v-show="!!item.quantity ">({{item.quantity}})</span>
       </div>
       <div>
         <el-dropdown class="dropdown" @command="handleCommand" trigger="click">
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
           <el-dropdown-menu slot="dropdown">
             <el-dropdown-item
                 v-for="item in list"
                 :key="item.id"
                 :command="item.id"
                 :class='{"active_dropdown":orderNameIndex === item.id}'
             >{{item.title}}
             <span v-show="!!item.quantity">({{item.quantity}})</span>
             </el-dropdown-item>
           </el-dropdown-menu>
         </el-dropdown>
       </div>
     </div>

<!--    按钮-->
    <div class="mt-10" >
      <el-button size="mini" type="primary" @click="importOrder">新增导入订单</el-button>
<!--      <el-button size="mini" :disabled="!checkedCities.length" :class="{'active':!!checkedCities.length}" @click="batchCancel" v-show="  orderNameIndex!== 0  && orderNameIndex < 4 ">批量取消订单</el-button>-->
      <el-button size="mini" :disabled="!checkedCities.length" :class="{'active':!!checkedCities.length}" v-show=" orderNameIndex === 2" @click="payment">批量支付</el-button>
    </div>

<!--    头部title-->
    <el-row class="bg_row" :gutter="10">
      <el-col :span="1" style="padding-left: 10px">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
      </el-col>
      <el-col :span="8">产品信息</el-col>
      <el-col :span="5">实付款</el-col>
      <el-col :span="4">物流</el-col>
      <el-col :span="4">订单状态</el-col>
      <el-col :span="2" class="tac">操作</el-col>
    </el-row>

<!--    数据list-->
    <div
        v-loading="loadingFbaList"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#fff"
    >
        <div class="fba_list" v-for="item in fbaPlanPaginationList" :key="item.fba_id" :class="{'transparent':item.status === 90}">
    <!--      上&#45;&#45;订单号-->
          <div class="orderNumber">

            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox :label="item.fba_id"></el-checkbox>
            </el-checkbox-group>
            <span class="pl-20" >订单号：</span><span>{{item.fba_number}}</span>
            <span class="pl-20" >PLAN ID：</span><span>{{item.plan_number}}</span>
            <span class="pl-20" >店铺：</span><span>【亚马逊 / {{setStore(item.store_code)}}】</span>
            <span class="pl-20" >导入人：</span><span>{{item.user.phone}}</span>
          </div>
    <!--      下&#45;&#45;内容-->
          <el-row :gutter="10">
            <el-col :span="9"  class="el_col_box">
              <div class="p-10 flex border_b" v-for="val in item.fba_items" :key="item.fba_item_id">
                <div class="img_box">
                  <el-image :src="val.design_item_info ? val.design_item_info.design_colors.imgThumb[0].img100 :noImage"></el-image>
                  <div class="cancel" v-show="item.status === 90">已取消</div>
                </div>
                <div class="content">
                  <div class="content_title">{{val.product_name}}</div>
                  <div class="item_content" style="margin-top: 10px">
                    <div>
                      <span>SKU：</span>
                      <span>{{val.seller_sku}}</span>
                    </div>
                    <div class="w-150">
                      <span >FNSKU：</span>
                      <span>{{val.fba_fnsku}}</span>
                    </div>
                  </div>

                  <template v-if="val.design_item_info">
                    <div class="item_content">
                      <div>
                        <span >规格：</span>
                        <span>{{!!val.design_item_info ? val.design_item_info.color_name : ''}} / {{!!val.design_item_info ?val.design_item_info.size_name : ''}}</span>
                      </div>
                      <div class="w-150">
                        <span>数量：</span>
                        <span>{{val.fba_qty}}</span>
                      </div>
                    </div>
                    <div class="item_content">
                      <div>
                        <span>货款：</span>
<!--                        product_discount_price  折扣价-->
<!--                        product_unit_price  原价-->
                        <span v-if="val.product_discount_price !== val.product_unit_price">
                          <span>￥{{ val.product_discount_price }} / 件</span>
                          <span class="product_unit_price" >{{val.product_unit_price}}</span>
                        </span>
                        <span v-else>￥{{val.product_unit_price}} / 件</span>
                      </div>
                      <div class="w-150">
                        <span >处理费：</span>
                        <span>￥{{val.processing_fee}} / 件</span>
                      </div>

                    </div>
                  </template>
                  <div v-show="item.status == 0 || item.status === 10">
                    <el-button style="margin-top: 10px;" size="mini" type="primary"  @click="relatedProducts(val)">
                      <span >{{val.design_item_info ? '重新关联' : '关联' }}</span>
                    </el-button>
                  </div>
<!--                  <el-button style="margin-top: 10px;" size="mini" type="primary" v-else @click="relatedProducts(val)">关联</el-button>-->
<!--                  <div v-if="!!val.design_item_info && item.status == 0 || item.status === 10" style="margin-top: 10px">-->
<!--                    <el-button  @click="relatedProducts(val)" type="primary" size="mini">重新关联</el-button>-->
<!--                  </div>-->
                </div>

              </div>
            </el-col>
            <el-col :span="5" class="public border_r">
              <div>产品货款：
                <span >￥{{item.fba_payment_price}}</span>
    <!--            <span v-if="orderNameIndex !== 1">￥{{item.fba_payment_price}}</span>-->
    <!--            <span v-else  class="color_red">未关联</span>-->
              </div>
              <div>FBA处理费：
                <span >￥{{item.fba_process_fee}}</span>
    <!--            <span v-if="orderNameIndex !== 1">￥2954.00</span>-->
    <!--            <span v-else class="color_red">未关联</span>-->
              </div>
              <div>包装费：
                <span>￥0.00</span>
    <!--            <span v-if="orderNameIndex !== 1">￥2954.00</span>-->
    <!--            <span v-else class="color_red">未关联</span>-->
              </div>
              <div >FBA运费：
                <span v-if="item.fba_shipping_price !== '0.00'">￥{{item.fba_shipping_price}}</span>
                <span v-else class="color_red">装箱后结算</span>
              </div>
              <div>小计：
                <span >￥{{setMoney(item)}}</span>
    <!--            <span v-if="orderNameIndex !== 1">￥2954.00</span>-->
    <!--            <span v-else class="color_red">未关联</span>-->
              </div>
              <div >(共 {{item.fba_qty}} 件)</div>
            </el-col>
            <el-col :span="4" class="public">
              <div>收货人：{{item.fba_address_name}}</div>
              <div v-if="!!item.fba_shipping">物流：{{!!item.ship_info ? item.ship_info.name :'暂无物流' }}</div>
              <div class="orderProgress" @click="modifyLogistics(item)" v-show="item.status === 0">
                {{ !item.ship_info || !item.ship_info.name ? '选择物流方式' : '修改物流方式'}}
              </div>
            </el-col>
            <el-col :span="4" class="public">
              <div>{{orderState(item.status)}}</div>
              <div class="orderProgress" @click="viewTags(item.fba_number)" v-show="item.status === 60 ||item.status === 70||item.status === 80 ">查看装箱标签</div>
              <span v-show="!(item.status === 0 || item.status === 10 || item.status === 90)" class="barCode" @click="viewBarcode(item.fba_product_pdf_url)">查看条形码</span>
              <div class="orderProgress" @click="orderProgress(item.fba_id)">订单进度</div>
<!--              <div v-if="!!item.created_at">导入时间：{{item.created_at}}</div>-->
<!--              <div v-if="!!item.payment_pay_time">支付时间：{{item.payment_pay_time}}</div>-->
            </el-col>
            <el-col :span="2" class="tac public operation" >
              <div @click="editOrder(item.fba_id)" >
                查看
              </div>
              <div class="orderProgress" @click="okToEdit(item)" v-show="item.status === 0">确定订单</div>
<!--              -->
              <div @click="uploadTag(item.fba_number)"  v-if="item.status === 50">上传标签</div>
              <div v-show="item.status === 10" @click="payOrder(item)" >支付货款</div>
              <div v-show="item.status === 60" @click="payOrder(item)" >支付运费</div>
              <div @click="modificationAddress(item.fba_id)" v-show=" cancelOrder(item.status)">修改地址</div>
              <div  @click="cancellOrder(item.fba_number)" v-show="cancelOrder(item.status)">取消订单</div>
              <div @click="deleteOrder(item)" v-show="item.status === 90">删除</div>
              <div @click="remarks(item)">备注</div>
            </el-col>
          </el-row>
        </div>
      <div v-if="noProductInfoShow" class="noProductInfo">
        <img :src="noOrder" alt="">
        <div>暂无订单信息</div>
      </div>
    </div>

    <paging :meta="meta" @currentPage="currentPage"/>

<!--    //导入订单-->
    <import-order
        v-model="dialogVisible"
        @update="update"
        :shopList="shopList"
    />

<!--    关联产品-->
    <related-products
    v-model="relatedProductsShow"
    :fbaItemId="fbaItemId"
    @update="fbaPlanPagination"
    />

<!--    订单进度-->
    <order-progress
    v-model="orderProgressShow"
    :fbaPlanPaginationItem="fbaPlanPaginationItem"
    />

<!--    修改物流地址-->
  <logistics-address
  v-model="logisticsAddressShow"
  :fbaPlanPaginationItem="fbaPlanPaginationItem"
  :siteList="siteList"
  :modifyLogisticsId="fbaId"
  @update="fbaPlanPagination"
  />

<!--    //订单信息 -- 编辑-->
    <order-information
    v-model="orderInformationShow"
    :fbaPlanPaginationItem="fbaPlanPaginationItem"
    :orderListStatus="orderListStatus"
    :siteList="siteList"
    :fbaId="fbaId"
    @update="update"
    :shopList="shopList"
    />

<!--    //支付货款-->
    <pay-order
        v-model="payOrderShow"
        :fbaNumber="fbaNumber"
        :totalMoney="totalMoney"
        :paymentTitle="paymentTitle"
        @close="payOrderClose"
        :fbaNumberList="fbaNumberList"
        :freightPayStatus="freightPayStatus"
        @update="update"
    />

<!--    上传标签-->
    <upload-tag v-model="uploadTagShow" :tagTitle="tagTitle" :fbaNumber="fbaNumber" @update="update" @close="fbaNumber=$event"/>
<!--    备注信息-->
    <remarks
        v-model="remarksShow"
        :fbaNumber="fbaNumber"
        :remarkText="remarkText"
        @update="fbaPlanPagination"
        @close="fbaNumber=$event"
        :freightPayStatus="freightPayStatus"
    />

<!--    //修改物流-->
    <modify-logistics
        v-model="modifyLogisticsShow"
        :factoryCode="factoryCode"
        :fbaNumber="fbaNumber"
        :fbaShipping="fbaShipping"
        @close="closeLogistics"
        @update="fbaPlanPagination"
    />

<!--    //查看装箱标签-->
<!--    <view-tags v-model="viewTagsShow" :fbaPdfUrl="fbaPdfUrl" />-->
  </div>
</template>

<script>
import noImage from '@/assets/noimage.png';
import noOrder from '@/assets/noOrder.png';
import ImportOrder from "./components/importOrder";
import RelatedProducts from "./components/relatedProducts";
import OrderProgress from "./components/orderProgress";
import LogisticsAddress from "./components/logisticsAddress";
import OrderInformation from "./components/orderInformation";
import {fbaPlanPagination, fbaPlanStatusSelect , deleteFbaPlan} from "@/api/order";
import Paging from "@/components/publicTab/index";
import {shopSelect} from "@/api/productTemplate";
import {dateFormat} from "@/utils/timejs";
import PayOrder from "./components/payOrder";
import UploadTag from "./components/uploadTag";
import Remarks from "./components/remarks";
import ModifyLogistics from "./components/modifyLogistics";
import ViewTags from "./components/viewTags";
import {cancelFBAPlan,confirmEdit } from "@/api/order";
import {countryList} from "@/api/order";


export default {
  name: "index",
  components: {
    ViewTags,
    ModifyLogistics,
    Remarks,
    UploadTag,
    PayOrder, Paging, OrderInformation, LogisticsAddress, OrderProgress, RelatedProducts, ImportOrder},
  data: () => ({
    page:1,
    perPage:5,
    noImage,
    noOrder,
    shop:'',
    tagTitle:null,//查看上传标签
    fbaNumber:null,
    freightPayStatus:null,
    clientWidth:null,//浏览器宽度
    fbaShipping:null,//列表物流code
    factoryCode:null,//物流code
    remarkText:"",//备注信息
    fbaPdfUrl:'',//标签链接
    stateId:undefined,//订单状态
    fbaId:null,//fbaId
    totalMoney:null,//小计金额
    paymentTitle:'',//支付title
    orderNameIndex:0, //订单状态
    isIndeterminate:false, //复选框待选
    checkAll:false, //复选框全选
    dialogVisible:false ,//新增订单弹窗
    relatedProductsShow:false,//关联订单弹窗
    orderProgressShow:false,//订单进度弹窗
    logisticsAddressShow:false,//物流地址弹窗
    payOrderShow:false,//支付弹窗
    uploadTagShow:false,//上传标签弹窗
    remarksShow:false,//备注弹窗
    modifyLogisticsShow:false,//修改物流方式弹窗
    viewTagsShow:false,//上传标签展示弹窗
    orderInformationShow:false,//订单信息
    noProductInfoShow:false,//无订单显示状态
    loadingStatus:true,//订单状态loading状态
    loadingFbaList:true,//订单listloading状态
    checkedCities:[],//复选框单选
    fbaPlanPaginationList:[],//fba订单数据
    fbaItemId:null,//关联产品id
    shopList:[],//店铺数组
    fbaPlanPaginationItem:{},//编辑数据
    siteList:[],//国家数组
    orderListStatus:[], //订单类型
    creationTime:[],//创建时间
    paymentTime:[],//支付时间
    fbaNumberList:[],//fba订单号 支持批量支付
    list :[],
    pickerOptions:{  // 超过当前时间禁选
      disabledDate(time) {
        return time.getTime() > Date.now()
      }
    },
    meta:{},//fba订单分页
  }),
  mounted() {
    //获取fba订单list
    this.fbaPlanPagination()
    // 获取定订单状态
    this.fbaPlanStatusSelect()
    // 获取店铺
    this.shopSelect()
    // 获取国家站点
    this.getSite()
    //浏览器变化
    // this.bodyClientWidthChange = throttle(300, this.bodyClientWidthChange)
    window.onresize = () => {
    this.clientWidth = document.body.clientWidth
      this.bodyClientWidthChange(this.clientWidth)
    }

  },
  methods: {
    //确定编辑
    okToEdit(item){
      console.log(item)
      this.$confirm('是否确定完成了对此发货计划的编辑',   {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { fba_id } = item
        try {
          const { msg } = await confirmEdit({
            fbaId:fba_id
          })
          this.$message.success(msg)
          await this.fbaPlanPagination()
          await this.fbaPlanStatusSelect()
        }catch (e) {
          this.$message.error(e?.msg)
        }

      }).catch(() => {
        return false
      });
    },
    //删除订单
    deleteOrder(item){

      const { fba_number } = item
      this.$confirm('此操作将永久删除该订单, 是否删除?',   {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {

        try {
          const { msg }  = await deleteFbaPlan({
            fbaNumber:fba_number
          })
          this.$message({
            type: 'success',
            message: msg
          });
          await this.fbaPlanPagination()
          await this.fbaPlanStatusSelect()
        }catch (e) {
          this.$message.error(e?.msg)
        }


      }).catch(() => {
      return false
      });
    },
    handleCommand(e){
      this.orderName(e,e)
    },
    //浏览器窗口变化
    bodyClientWidthChange(e){
       if( e <= 1350) {
        this.list = this.orderListStatus.filter((item,index)=> index >= 8)
      }
    },

    //关闭物流
    closeLogistics(){
      this.fbaNumber = null
      this.fbaShipping = null
    },

    //更新
    update(){
      this.checkedCities = []
      this.checkAll = false
      this.isIndeterminate = false
      this.fbaPlanStatusSelect()
      this.fbaPlanPagination()
    },

    //上传标签
    uploadTag(val){
      this.tagTitle = '上传标签'
      this.fbaNumber = val
      this.uploadTagShow = true
    },

    //查看上传标签
    viewTags(val) {
      this.tagTitle = '查看标签'
      this.fbaNumber = val
      this.uploadTagShow = true
    },

    //单选复选框
    handleCheckedCitiesChange(e){
      let arr = []
      this.fbaPlanPaginationList.forEach(item=>{
        arr.push(item.fba_id)
      })
      this.checkAll = e.length === arr.length
      this.isIndeterminate = e.length > 0 && e.length < arr.length
    },

    //全选复选框
    handleCheckAllChange(val){
      let arr = []
      this.fbaPlanPaginationList.forEach(item=>{
        arr.push(item.fba_id)
      })
      this.isIndeterminate = false
     !!val ? this.checkedCities = arr : this.checkedCities = []

    },

    //获取店铺
    async shopSelect() {
      try {
        const data = await shopSelect()
        this.shopList = data?.data
      } catch (e) {
        const {msg} = e
        this.$message.error(msg)
      }
    },

    //订单状态列表
    async fbaPlanStatusSelect(){
      // this.loadingStatus = true
      let sum = 0
        try {
          const {data} = await fbaPlanStatusSelect()
          //获取总订单数
          data.forEach(val=>{
            if(!!val.quantity) {
              sum +=Number(val.quantity)
            }

          })
          this.orderListStatus = [ {
            id:undefined,
            title:'全部订单',
            quantity:sum
          },
            ...data]
          this.loadingStatus = false
          this.bodyClientWidthChange(document.body.clientWidth)
        }catch (e) {
          this.$message.error(e?.msg)
        }
    },

    // 分页
    currentPage(e){
      this.page = e
      this.fbaPlanPagination()
    },

    //fba列表
    async fbaPlanPagination(){
      this.loadingFbaList = true
      try {
        const { data } = await fbaPlanPagination({
          page:this.page,
          perPage:this.perPage,
          status:this.stateId,
          storeCode:this.shop,
          createdTimeRange:this.creationTime.length ? this.creationTime :this.creationTime = [],
          payTimeRange:this.paymentTime.length ? this.paymentTime :this.paymentTime = []

        })
        this.fbaPlanPaginationList = data?.data?.list

        !this.fbaPlanPaginationList.length ?   this.noProductInfoShow = true : this.noProductInfoShow = false
        this.meta = data?.meta
        this.page =data?.meta?.current_page
        this.perPage = parseInt(data?.meta?.per_page)
        this.loadingFbaList = false
      }catch (e) {
        this.$message.error(e?.msg)
      }
    },

    //处理单个数据
    getFbaItem(id){
      this.fbaPlanPaginationList.forEach(item=>{
        if(item.fba_id === id) {
          this.fbaPlanPaginationItem = item
        }
      })
    },

    //获取国家站点
    async getSite(){
      try {
      const {data} = await countryList()
        // data.amazonRegionList.forEach(item=>{
        //   item.countrys.forEach(val=>{
        //     this.siteList.push(val)
        //   })
        // })
        // console.log(data)
        this.siteList = data
      }catch (e) {
        this.$message.error(e?.msg)
      }
    },

    //备注
    remarks(val){
      const { fba_number ,remark,status} = val
      this.remarkText = remark
      this.fbaNumber = fba_number
      this.remarksShow = true
      this.freightPayStatus = status
    },

    //查看条形码
    viewBarcode(url){
      if(!!url) {
        window.open(url)
      } else {
        this.$message.error('条形码暂未生成')
      }

    },

  //region关联产品
    relatedProducts(val){
      this.fbaItemId = val.fba_item_id
    this.relatedProductsShow = true
    },
  //endregion

    //region搜索
    onSubmit(){
      if(this.creationTime.length) {
        this.creationTime = [dateFormat(this.creationTime[0]),dateFormat(this.creationTime[1])]
      }
      if(this.paymentTime.length) {
        this.paymentTime = [dateFormat(this.paymentTime[0]),dateFormat(this.paymentTime[1])]
      }

      this.page = 1
      this.perPage = 5
      this.fbaPlanPagination()
    },
    //endregion

    //region重置
    onReset(){
      this.page = 1
      this.perPage = 5
      this.shop = ''
      this.creationTime = []//创建时间
      this.paymentTime = []//支付时间
      this.fbaPlanPagination()
    },
    //endregion

    //region 订单状态
    orderName(i,id) {
      this.page = 1
      this.perPage = 5
      this.stateId = id
      this.orderNameIndex = i
      this.checkedCities = []
      this.checkAll = false
      this.isIndeterminate = false
      this.fbaPlanPagination()
      this.fbaPlanStatusSelect()
    },
    //endregion

    //region 新增订单按钮
    importOrder(){
      this.dialogVisible = true
    },
    //endregion

    // region 取消订单
    cancellOrder(val){
      this.$confirm('此操作将取消此订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
       try {
         const {msg} = await cancelFBAPlan({
           fbaNumber:val
         })
         this.$message.success(msg)
       }catch (e) {
         this.$message.error(e?.msg)
       }

      await this.fbaPlanPagination()
      await this.fbaPlanStatusSelect()
      }).catch((e)=>{
        return false
      })
    },
    //endregion

    //region订单进度
    orderProgress(id){
      this.getFbaItem(id)
      this.orderProgressShow = true
    },
    //endregion

    //region修改收货地址
    modificationAddress(id){
      this.fbaId = id
      this.getFbaItem(id)
      this.logisticsAddressShow = true
    },
    //endregion

    //region 编辑订单信息
    async editOrder(id){
      this.fbaId = id
      this.getFbaItem(id)
      this.orderInformationShow = true
    },
    //endregion

    //region 批量取消订单
    batchCancel(){
      this.$confirm('此操作将取消已勾选的订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {

        if(!!this.checkedCities.length){
          let arr = []
          this.fbaPlanPaginationList.forEach(item=>{
            this.checkedCities.forEach(val=>{
              if(item.fba_id === val) {
                arr.push(item.fba_number)
              }
            })
          })
         try {
           const {msg} = await cancelFBAPlan({
             fbaNumber:arr
           })
           this.$message({
             type: 'success',
             message: msg
           });
         }catch (e) {
           this.$message.error(e?.msg)
         }
        }else {
          this.$message.error('请勾选订单再操作')
        }

      }).catch(()=>{

        return false
      })
    },
    //endregion订单

    //region 支付货款
    payOrder(item){

      const { fba_number,totalMoney,status ,fba_shipping_price} = item
      this.freightPayStatus = status
      this.fbaNumber = fba_number
      this.totalMoney = totalMoney
      status === 10 ?  this.paymentTitle = '订单支付' : this.paymentTitle = '运费支付'
      status === 10 ?  this.totalMoney = totalMoney : this.totalMoney = fba_shipping_price
      this.payOrderShow = true
    },
    //endregion

    //region批量支付
    payment(){
      this.freightPayStatus = this.stateId
      this.paymentTitle = '批量支付'
      this.payOrderShow = true
      this.fbaPlanPaginationList.forEach(item=>{
        this.checkedCities.forEach(val=>{
          if(item.fba_id === val) {
            this.totalMoney = (Number(item.totalMoney) + Number(this.totalMoney)).toFixed(2)
            this.fbaNumberList.push(item.fba_number)
          }
          console.log(this.fbaNumberList)
        })
      })
    },
    //endregion

    //region批量支付关闭
    payOrderClose(){
      this.totalMoney = null
      this.fbaId = null
      this.fbaNumber = null
      this.fbaNumberList = []
    },
    //endregion

    //region 修改物流方式
    modifyLogistics(item){
      const { factory_code ,fba_number ,fba_shipping} = item
      if(!!factory_code) {
        console.log('物流code',factory_code)
        this.factoryCode = factory_code
        this.modifyLogisticsShow = true
        this.fbaNumber = fba_number
        this.fbaShipping = fba_shipping
      } else {
        this.$message.error('请先关联产品')
      }


    },
    //endregion
  },
  watch: {},
  computed:{
    //订单状态
    orderState(){
      return (val)=>{
        // 0=待编辑，1=待付货款，2=已付货款，3=生产中/待装箱，4=待支付运费，5=待上传装箱标签，6=待发货，7=已发货，8=已完成，9=已取消
        let title
        this.orderListStatus.forEach(item=>{
          if(item.id === val) {
            title = item.title
          }
        })
        return title
      }
    },
    //店铺
    setStore(){
      return (val)=>{
        let name
        this.shopList.forEach(item=>{
          if(item.code === val) {
            name = item.name
          }
        })
        return name
      }
    },
    //取消订单
    cancelOrder() {
      return (val)=>{
        return val === 0 || val === 10 || val === 20 || val === 30
      }

    },
    //小计
    setMoney(){
      return (item)=>{
        // fba_payment_price  产品货款
        // fba_process_fee  FBA处理费
        //fba_shipping_price  FBA运费
        const { fba_payment_price ,fba_shipping_price ,fba_process_fee} = item
        let sum
        if(!!fba_shipping_price) {
          sum = (Number(fba_shipping_price) + Number(fba_payment_price) + Number(fba_process_fee)).toFixed(2)
        } else {
          sum = (Number(fba_payment_price) + Number(fba_process_fee)).toFixed(2)
        }
        this.$set(item,'totalMoney',sum)
        return sum
      }
    },

  }

}
</script>

<style scoped lang="less">
.border_r {
//border-left: 1px solid #ccc !important;
  padding-left: 10px !important;
}
.active_dropdown {
  background-color: #ffede8;
  color: #fb6e43;
}
.dropdown {
  font-size: 13px;
  line-height: 37px;
  color: #000 !important;
  display: none;
}
.active {
  background: #FA4A14;
  color: #fff;
}
/deep/.el-checkbox-group {
  display: inline-block;
}
/deep/.el-checkbox__label {
  display: none;
}
.noProductInfo {
  text-align: center;
  margin-top: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #ccc;
}
.color_red {
  color: red;
}
.flex {
  display: flex;
}
.mt-10 {
  margin-top: 10px;
}
.p-10 {
  padding: 10px;
}
.pl-20 {
  padding-left: 20px;
  color: #909399;
}
/deep/.el-form-item__content {
  flex: 1;
  width: 100%;
}
/deep/.el-input .el-input--mini {
  width: 100%;
}
/deep/.el-select--mini {
  width: 100%;
}
/deep/.el-input__inner {
  width: 100% !important;
}
/deep/.el-form-item {
  display: flex;
  width: 100%;
}
/deep/.el-form-item__label {
  width: auto !important;
}
/deep/.el-col {
  border: 1px solid transparent;
}
.order_status {
  display: flex;
  border-bottom: 1px solid #eee;
  .order_name {
    font-size: 13px;
    margin: 0 10px;
    padding: 10px 5px;
    cursor: pointer;
    user-select: none;
    &:nth-child(1) {
      margin-left: 0 !important;
    }
  }
  .order_status_name {
    color: #FA4A14;
    border-bottom: 2px solid #FA4A14;
    border-radius: 1px;
  }
}
.bg_row {
  font-weight: 600;
  margin-top: 10px;
  background: #F7F8FA;
  line-height: 40px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 20px;
}
.fba_list {
  width: 100%;
  //height: 300px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  .orderNumber {
    height: 30px;
    border-bottom: 1px solid #ccc;
    padding-left: 10px;
    line-height: 30px;
    font-size: 13px;

  }
  .border_b {
    //border-top: 1px solid #ccc;
    height: 100%;

  }
  .el_col_box {
    height: 100%;
    border-right: 1px solid #ccc;
    padding-right: 0 !important;
    & > div:nth-child(1) {
      border-top: none;
    }

  }
}
.transparent {
  opacity: .4;
}
.content {
  flex: 1;
  font-size: 12px;
  padding-left: 10px;
  .content_title {
    letter-spacing: 0;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;  /*要显示的行数*/
    -webkit-box-orient: vertical;
  }
  .item_content {
    display: flex;
    justify-content: space-between;
    .w-150 {
      width: 150px;
    }
    .product_unit_price {
      color: #cccccc;
      margin-left: 10px;
      text-decoration:line-through;
    }
  }
}
.img_box {
  width: 100px;
  height: 100px;
  position: relative;
  &>.el-image {
    width: 100%;
    height: 100%;
  }
  .cancel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)  rotate(-30deg);
    border: 1px dashed  red;
    width: 60px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: red;
    font-weight: 600;
    font-size: 14px;
  }
}
.public {
  font-size: 12px;
  line-height: 18px;
  padding-top: 10px;
  .barCode {
    cursor: pointer;
    color: #FA4A14;
    font-weight: 600;
  }
}
.operation {
  cursor: pointer;
  user-select: none;
  &>div:hover {
    color:#FA4A14 ;
  }

}
.orderProgress {
  cursor: pointer;
  color: #FA4A14;
  font-weight: 600;
  font-size: 12px;
}
.tac {
  text-align: center;
}
@media (max-width:1500px) {
  .item_content{
    display: block !important;
  }
  .w-150 {
    width: 100% !important;
  }
  //.order_name {
  //  margin: 0 6px !important;
  //}
}

@media (max-width:1350px) {
    .dropdown {
      display: flex!important;
      user-select: none;
    }
  .tab_item_0,
  .tab_item_1,
  .tab_item_2,
  .tab_item_3,
  .tab_item_4,
  .tab_item_5,
  .tab_item_6,
  .tab_item_7
  {
    display: flex !important;
  }
}
@media (min-width:1350px) {

  .tab_item
  {
    display: flex !important;
  }
}

.tab_item {
  display: none ;
}
</style>