<template>
  <el-dialog
      :title="paymentTitle"
      :visible.sync="value"
      width="600px"
      :before-close="handleClose">
<!--    支付tab-->
    <div class="tba_title">
      <div class="balance" @click="payBalance(1)" :class="{'active':acitveIndex === 1}">余额支付</div>
      <div @click="payAlipay(2)" :class="{'active':acitveIndex === 2}">支付宝支付</div>
    </div>

    <div class="border_b">
      <div class="payMoney">
        <div>支付金额</div>
        <div class="color_money">￥{{totalMoney}}</div>
      </div>
      <div class="payMoney" v-show="acitveIndex !== 2">
        <div>余额</div>
        <div class="color_money">￥{{balance}}</div>
      </div>
      <div class="recharge " v-show=" acitveIndex !== 2 && noBalance">余额不足，请充值</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button v-if="setPay ||acitveIndex === 2 " type="primary" @click="payOrderMoney" :loading="playLoading">支 付</el-button>
      <el-button v-else type="primary" @click="recharge">充 值</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { getWalletBalance } from '@/api/costManagement'
import {fbaPlanPayByAlipay, fbaPlanPayByYue} from "@/api/order";
export default {
  name: "payOrder",
  props:{
    value:{
      type:Boolean,
      default:false
    },
    fbaNumber:{
      type:String,
      default: null
    },
    totalMoney:{
      default:null
    },
    paymentTitle:{
      type:String
    },
    fbaNumberList:{
      type:Array,
      default:()=>([])
    },
    freightPayStatus:{
      type:Number,
      default:null
    }
  },
  data: () => ({
    acitveIndex:1,
    balance:null,//余额
    playLoading:false,
  }),
  mounted() {
    this.getWalletBalance()
  },
  methods: {
    handleClose(){
      this.$emit('input',false)
      this.$emit('close')
    },
    //支付
    async payOrderMoney(){
      if(this.acitveIndex === 1) {
        this.playLoading = true
        //余额支付
        try {

          const { msg } = await fbaPlanPayByYue({
            fbaNumber:this.fbaNumber || this.fbaNumberList,
            fbaStatus:this.freightPayStatus
          })
          this.$message.success(msg)
          this.$emit('update')

        }catch (e) {
          this.$message.error(e?.msg)
        }
      } else {
        //支付宝支付
        try {

          const { data } = await fbaPlanPayByAlipay({
            fbaNumber:this.fbaNumber || this.fbaNumberList,
            fbaStatus:this.freightPayStatus
          })
          let divForm = document.getElementsByTagName('divform')
          if (divForm.length) {
            document.body.removeChild(divForm[0])
          }
          const div = document.createElement('divform')
          div.innerHTML = data.info
          document.body.appendChild(div)
          document.getElementById('alipay_submit').submit()


          this.$emit('update')
        }catch (e) {
          this.$message.error(e?.msg)
        }
      }
      this.handleClose()
      this.playLoading = false
    },

    // 余额支付tbas
    payBalance(e){
      this.acitveIndex = e
    },
    //支付宝支付tbas
    payAlipay(e){
      this.acitveIndex = e
    },

    // 余额查询
    async getWalletBalance(){
      try{
        const { data } = await getWalletBalance()

        this.balance = data?.info?.amount
      } catch(e){
        console.log(e);
        const {msg} = e
        this.$message.error(msg)
      }
    },

    //充值
    recharge(){
      this.$router.push({name:'expenseManagement',query:{flag:'1'}})
    }

  },
  watch: {
  },
  computed:{
    noBalance(){
      return parseFloat(this.balance)  <  parseFloat(this.totalMoney)
    },
    setPay(){
      return parseFloat(this.balance)  >  parseFloat(this.totalMoney)
    }
  }

}
</script>

<style scoped lang="less">
.tba_title {
  display: flex;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  .active {
    color:#fa4a14;
   padding-bottom: 10px;
    border-bottom: 3px solid #fa4a14;
  }
  .balance {

    margin-right: 30px;
  }
}
.payMoney {
  display: flex;
  justify-content: space-between;
  padding:20px 20px 10px;
  .color_money {
    color:#fa4a14 ;
  }
}
.border_b {
  height: 145px;
  border-bottom: 1px solid #eee;
}
.recharge{
  padding-left: 20px;
  margin-top: 20px;
  font-size: 12px;
  color: red;
  padding-bottom: 10px;
}
</style>