<template>
  <el-dialog
      title="收货地址"
      :visible.sync="value"
      width="50%"
      @open="open"
      :before-close="handleClose">
    <div class="border"></div>
    <div class="hot">
      一般FBA收件人信息：FBA (9/17/21, 11:37 PM) - 1,大概率存在扣关风险。建议导入FBA发货计划后，自行修改收货人和收货手机电话。
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-row style="margin-top: 20px;" :gutter="20">
        <el-col :span="12">
          <el-form-item label="收货人：" prop="consignee">
            <el-input placeholder="请输入收货人" v-model="ruleForm.consignee"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="国家：" prop="country">
            <el-select  placeholder="请选择国家" v-model="ruleForm.country">
              <el-option :label="item.name_zh" :value="item.country_code" v-for="item in siteList" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="省 / 州：" prop="region">
            <el-input placeholder="请输入地区" v-model="ruleForm.region"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮编：" prop="zipCode">
            <el-input placeholder="请输入邮编" v-model="ruleForm.zipCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="城市：" prop="city">
            <el-input placeholder="请输入城市" v-model="ruleForm.city"></el-input>
          </el-form-item>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-form-item label="电话：" prop="telePhone">-->
<!--            <el-input placeholder="请输入电话" v-model="ruleForm.telePhone"></el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="12">
          <el-form-item label="手机：" prop="phone">
            <el-input placeholder="请输入手机号" v-model="ruleForm.phone"></el-input>
          </el-form-item>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-form-item label="邮箱：" prop="mailbox">-->
<!--            <el-input placeholder="请输入邮箱" v-model="ruleForm.mailbox"></el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="24">
          <el-form-item label="地址：" prop="address">
            <el-input placeholder="请输入地址"  type="textarea" v-model="ruleForm.address"></el-input>
          </el-form-item>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-form-item label="仓库编码：" prop="warehouseCode">-->
<!--            <el-input placeholder="请输入仓库编码" v-model="ruleForm.warehouseCode"></el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="determine">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import {alterAddress} from "@/api/order";

export default {
  name: "logisticsAddress",
  props:{
    value:{
      type:Boolean,
      default:false
    },
    fbaPlanPaginationItem:{
      type:Object,
      default: ()=>({})
    },
    siteList:{
      type:Array,
      default:()=>([])
    },
    modifyLogisticsId:{
      type:Number,
      default:null
    }
  },
  data: () => ({

    ruleForm:{
      country:'',//国家
      consignee:"",//收货人
      region:'',//省 / 州
      zipCode:"",//邮编
      city:'',//城市
      telePhone:'',//电话
      address:'',//地址
      phone:'',//手机号
      mailbox:'',//邮箱
      warehouseCode:"",//仓库编码
    },
    rules:{
      country:[{ required: true, message: '请选择国家', trigger: 'blur' }],
      consignee:[{ required: true, message: '请输入收货人', trigger: 'blur' }],
      region:[{ required: true, message: '请输入省 / 州', trigger: 'blur' }],
      zipCode:[{ required: true, message: '请输入邮编', trigger: 'blur' }],
      city:[{ required: true, message: '请输入城市', trigger: 'blur' }],
      telePhone:[{ required: true, message: '请输入电话', trigger: 'blur' }],
      address:[{ required: true, message: '请输入地址', trigger: 'blur' }],
      // phone:[{ required: true, message: '请输入手机号', trigger: 'blur' }],
      mailbox:[{ required: true, message: '请输入邮箱', trigger: 'blur' }],
      warehouseCode:[{ required: true, message: '请输入仓库编码', trigger: 'blur' }],
    }
  }),
  mounted() {

  },
  methods: {
    handleClose(){
      this.ruleForm = {
        country:'',//国家
            consignee:"",//收货人
            region:'',//省 / 州
            zipCode:"",//邮编
            city:'',//城市
            telePhone:'',//电话
            address:'',//地址
            phone:'',//手机号
            mailbox:'',//邮箱
            warehouseCode:"",//仓库编码
      }
      this.$refs.ruleForm.resetFields();
      this.$emit('input',false)
    },
    determine(){
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            const {msg}  = await alterAddress({
              name:this.ruleForm.consignee,
              fbaId:this.modifyLogisticsId,
              country:this.ruleForm.country,
              state:this.ruleForm.region,
              city:this.ruleForm.city,
              line:this.ruleForm.address,
              phone:this.ruleForm.phone,
              postcode:this.ruleForm.zipCode
            })
            this.$message.success(msg)
            this.handleClose()
            this.$emit("update")
          }catch (e) {
            this.$message.error(e?.msg)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    open(){
      console.log(this.fbaPlanPaginationItem)
      this.ruleForm.consignee = this.fbaPlanPaginationItem.fba_address_name //收货人
      this.ruleForm.country = this.fbaPlanPaginationItem.fba_address_country //国家
      this.ruleForm.region = this.fbaPlanPaginationItem.fba_address_state //省
      this.ruleForm.zipCode = this.fbaPlanPaginationItem.fba_address_postcode //邮编
      this.ruleForm.city = this.fbaPlanPaginationItem.fba_address_city //城市
      this.ruleForm.phone = this.fbaPlanPaginationItem.fba_address_phone //电话
      this.ruleForm.address = this.fbaPlanPaginationItem.fba_address_line//地址
    }
  },
  watch: {
  },

}
</script>

<style scoped lang="less">
/deep/.el-dialog__body {
  padding: 0 20px 10px;
}
/deep/.el-select {
  width: 100%;
}
/deep/.el-form-item__label {
  padding: 0;
}
.border {
  border: 1px solid #eee;
  margin-bottom: 10px;
}
.hot {
  font-size: 12px;
  background:#eee;
  padding:5px;
  color: red;
}
</style>