<template>
  <div>
    <el-dialog
        title="新增导入FBA发货计划"
        :visible="value"
        :before-close="handleClose">
          <div class="border"></div>
          <el-form ref="refsForm"  :model="form" :rules="rules">
            <el-form-item label="请选择导入模版" prop="amazon" style="padding-left: 20px;" >
              <el-radio-group v-model="form.amazon">
                <el-radio label="1">亚马逊</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="请选择导入店铺" prop="shop" style="padding-left: 20px;">
              <el-radio-group  style="display: flex;flex-wrap: wrap" v-model="form.shop">
                <el-radio class="el_radio" v-for="item in shopList" :label="item.code"> {{ item.name }} </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div class="explanation_box">
            <div class="ml-b">1. 文件格式及范例：.tsv</div>
            <div >2. tsv文件下载方式：</div>
            <div class="ml_15">1). 登录亚马逊商家后台，进入“库存 > 管理亚马逊库存”模块；</div>
            <div class="ml_15">2). 在“亚马逊库存工具”点击“货件处理进度”，进入货件处理进度页面；</div>
            <div class="ml-b ml_15">3). 进入页面后，点击“处理货件”后的箭头，点击“下载SKU列表”，等待下载完成即可；</div>
            <div >3. 温馨提示  </div>
            <div class="ml_15">接DHL物流合作商提醒：建议不同的订单，收件人和收件电话不重复，否则可能导致海关抽检涉嫌分票逃避海关正式报关的监管。</div>
            <div class="ml_15">例如：一般FBA收件人信息：FBA (9/17/21, 11:37 PM) - 1,大概率存在扣关风险。建议导入FBA发货计划后，自行修改收货人和收货手机电话。
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="importTsv">导入.tsv文件</el-button>
          </span>
          <input type="file" name="" id="" style="display: none" ref="inputFile" @change="changeFile">
    </el-dialog>
  </div>
</template>

<script>
import {shopSelect} from "@/api/productTemplate";
import {importFBAPlan} from "../../../../api/order";
export default {
  props:{
    value:{
      type:Boolean,
      default:false
    },
    shopList:{
      type:Array,
      default: ()=>([])
    }
  },
  name: "importOrder",
  data: () => ({


    form:{
      amazon:'1',
      shop:'',//店铺
    },
    rules:{
      amazon:{ required: true, message: '请输入活动名称', trigger: 'blur' },
      shop:{ required: true, message: '请输入活动名称', trigger: 'blur' },
    },
  }),
  mounted() {

  },
  methods: {
    //获取店铺

    //导入tsv文件
    importTsv(){
      this.$refs.refsForm.validate((valid) => {
        if (valid) {
         this.$refs.inputFile.click()
        } else {
          this.$message.error('请选择店铺')
          return false;
        }
      });
    },
    //关闭弹窗
    handleClose(){
      this.$refs.refsForm.resetFields();
      this.$emit('input',false)
    },
    //文件变化
    async changeFile(e){
      console.log(e.target.value)
      console.log(this.$refs.inputFile.value)
      try {
        const { msg } = await importFBAPlan({
          fbaPlanFile:e.target.files[0],
          storeId:this.form.shop
        })
        this.$refs.inputFile.value = null

        this.$message.success(msg)
        this.handleClose()
        this.$emit('update')

      }catch (e) {
        this.$refs.inputFile.value = null
        this.$message.error(e?.msg)
      }
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">
.el_radio {
  line-height: 40px;
}
/deep/ .el-dialog {
  width: 820px;
  //height: 657px;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  overflow: auto;
}

/deep/ .el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 25px;
}

/deep/ .el-dialog__body {
  padding: 10px 20px;
  position: relative !important;
}
.note {
  font-weight: 600;
  color: #333;
  line-height: 20px;
}
.border {
  width: 760px;
  height: 1px;
  background: #D8D8D8;
  margin-bottom: 10px;
}
.ml_15 {
  margin-left: 15px;
}
/deep/ .el-form-item {
  margin-bottom: 0;
}

.explanation_box {
  width: 720px;
  height: 285px;
  background: #F7F7F7;
  border: 1px dashed #D8D8D8;
  margin-left: 20px;
  padding: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  line-height: 20px;
  margin-top: 20px;
}


.primary {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 15px;
}

.inputFile {
  display: none;
}

/deep/ .el-dialog__footer {
  padding-top: 0;
}

.dialog-footer {
  margin-top: 20px;
  padding: 0 20px !important;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  .upload-demo {
    display: flex;

    .primary {
      margin: 0;
      margin-left: 10px;
    }
  }
}

/deep/ .el-dialog{
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  /*height:600px;*/
  max-height:calc(100% - 30px);
  max-width:calc(100% - 30px);
}
/deep/ .el-dialog .el-dialog__body{
  flex:1;
  //overflow:auto;
}
.ml-b {
  margin-bottom: 10px;
}

/deep/.el-radio-group {
  width: 615px;

}
</style>