<template>
  <el-dialog
      title="选择物流"
      :visible.sync="value"
      width="700px"
      @open="open"
      :before-close="handleClose">
    <div v-loading="loading"
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="#fff"
    >
      <el-row class="title">
        <el-col :span="9" style="padding-left: 20px">物流方式</el-col>
<!--        <el-col :span="5">预计时效</el-col>-->
        <el-col :span="5">价格</el-col>
        <el-col :span="10">物流描述</el-col>
      </el-row>
      <div class="logistics_content">
        <el-row class="content" v-for="(item,index) in factoryList" :key="index">
          <el-col :span="9" style="padding-left: 20px">
            <el-radio v-model="flag"   :label="item.channel_code">{{item.name}}</el-radio>
          </el-col>
<!--          <el-col :span="5">3-8工作日</el-col>-->
          <el-col :span="5">装箱后结算</el-col>
          <el-col :span="10"  >{{!!item.description ? item.description : '暂无描述'}}</el-col>
        </el-row>
      </div>
<!--      <div class="chargedWidth" v-show="chargedWidthShow">-->
<!--        <div>一、计费重量</div>-->
<!--        <div class="ml_25">加拿大：起重0.1KG，以0.1KG为单位进位，包裹实际重量和体积重量相比，取较大者计算，体积重量计算方式为:长*宽*高cm/6000=KG</div>-->
<!--        <div class="ml_25">其他国家体积重低于实际重量2倍的，按照实际重量收费；达到或超过实际重量2倍的，按照体积重量收取。(体积重=长*宽*高/8000)</div>-->
<!--        <div>二、重量要求</div>-->
<!--        <div class="ml_25">1.英国、德国、美国、西班牙：0{{'<'}}W≤3KG；</div>-->
<!--        <div class="ml_25">2.法国、意大利：0{{'<'}}W≤2KG；</div>-->
<!--        <div class="ml_25">3.加拿大：0{{'<'}}W≤5KG；</div>-->
<!--      </div>-->
    </div>

    <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="determine" :disabled="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {getFBAShippingMethods, setFBAShippingMethods} from "@/api/order";

export default {
  props:{
    value:{
      type:Boolean,
      default:false
    },
    factoryCode:{
      type:String,
      default:null
    },
    fbaNumber:{
      type:String,
      default:null
    },
    fbaShipping:{
      type:String,
      default:null
    }
  },
  name: "modifyLogistics",
  data: () => ({

    factoryList:[],//物流list
    flag:false,
    loading:true
  }),

  mounted() {

  },
  methods: {
    async determine(){
      if(!!this.flag) {
        try {
          const {msg} = await setFBAShippingMethods({
            fbaNumber:this.fbaNumber,
            channelCode:this.flag
          })
          this.$message.success(msg)
          this.handleClose()
          this.$emit('update')

        }catch (e) {
          this.$message.error(e?.msg)
        }
      } else {
        this.$message.error('请选择物流')
      }

    },
    open(){
      this.getFBAShippingMethods()
      this.flag = this.fbaShipping
    },
    handleClose(){
      this.$emit('input',false)
      this.$emit('close')

      this.factoryList = []
    },

    //获取物流
    async getFBAShippingMethods(){
      this.loading = true
      if(!!this.factoryCode) {
        try {
          const {data} = await getFBAShippingMethods({
            factoryCode:this.factoryCode
          })
          this.factoryList = data
          this.loading = false
        }catch (e) {
          this.$message.error(e?.msg)
        }
      } else {
        this.$message.error('请先关联产品')
      }

    }
  },
  watch: {},

}
</script>

<style scoped lang="less">
.logistics_content {
  height: 215px;
  overflow: auto;
  .content {
    line-height: 40px;
    border-bottom: 1px solid #eee;
    &:hover {
      background: #eee;
    }

  }
}
/deep/.el-dialog__header {
  border-bottom: 1px solid #eee;
}
/deep/.el-dialog__body {
  padding: 20px 20px;
}
.title {
  background: #ccc;
  line-height: 40px;
}

.chargedWidth {
  margin-top: 10px;
  line-height: 20px;
  font-size: 12px;
  padding-left: 25px;
  .ml_25 {
    margin-left: 25px;
  }
}
::-webkit-scrollbar {
  width: 8px; /*对垂直流动条有效*/
  height: 20px; /*对水平流动条有效*/
  // background-color:red
}
::-webkit-scrollbar-thumb{
  border-radius: 7px;
  background-color: #E8E8E8;
}
/deep/.el-message .el-message--error {
  z-index: 99999 !important;
}
</style>