<template>
  <div>
    <el-dialog
        title="订单进度"
        :visible.sync="value"
        width="40%"
        :before-close="handleClose">

         <div class="order_box">
           <el-steps direction="vertical"  :active="1" space="100px" align-center style="flex: 1" >
             <el-step icon="el-icon-location" class="el_step" v-for="(item,index) in fbaPlanPaginationItem.plan_process" :key="index">
               <div slot="title" class="title">{{item.time}}</div>
               <div slot="description">
                 <div class="description_title">{{item.title}}</div>
                 <div>{{item.describe}}</div>
               </div>
             </el-step>
           </el-steps>
         </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "orderProgress",
  data: () => ({}),
  props:{
    value:{
      type:Boolean,
      default:false
    },
    fbaPlanPaginationItem:{
      type:Object,
      default: ()=>({})
    }
  },
  mounted() {

  },
  methods: {
    handleClose(){
      this.$emit('input',false)
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">
.flex {
  display: flex;
}
.el_step {
  position: relative;

  padding-left: 180px;
  .title {
    position: absolute;
    left: 20px;
    font-size: 14px;
  }
  .description_title {
    font-weight: 600;
    font-size: 14px;
  }
}
.order_box {
  height: 400px;
  overflow: auto;
}
/* 滚动槽（轨道）宽高 */
::-webkit-scrollbar {
  width: 8px; /*对垂直流动条有效*/
  height: 8px; /*对水平流动条有效*/
  // background-color:red
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
  border-radius: 7px;
  background-color: #E8E8E8;
}
</style>