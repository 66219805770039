<template>
  <div>
    <el-dialog
        title="订单信息"
        :visible.sync="value"
        width="60%"
        :before-close="handleClose">
      <!--      头部信息-->
        <div class="flex order_info_top">
          <div class="flex">
            <div>
              <span class="color_text">订单号：</span>
              <span>{{fbaPlanPaginationItem.fba_number}}</span>
            </div>
            <div>
              <span class="color_text ml_20">PLAN ID：</span>
              <span>{{fbaPlanPaginationItem.plan_number}}</span>
            </div>
          </div>
          <div class="flex">
            <div>
              <span class="color_text">店铺：</span>
              <span>【亚马逊 / {{setStore(fbaPlanPaginationItem.store_code)}}】</span>
            </div>
            <div>
              <span class="color_text ml_20">订单状态：</span>
              <span style="padding-right: 10px;"> {{shopState(fbaPlanPaginationItem.status)}}</span>
            </div>
          </div>

        </div>
        <div class="orderInfo">
          <!--      产品信息-->
          <div class="product_info_box">
            <div class="product_info_top">
              产品信息
            </div>
            <div class="flex product_info_content" v-for="item in fbaPlanPaginationItem.fba_items" :key="item.fba_item_id">
              <el-row style="width: 100%;" :gutter="20">

                <el-col :span="12" class="flex">
                  <div class="img_box">
                    <el-image :src="item.design_item_info ? item.design_item_info.design_colors.imgThumb[0].img100 :noimage"></el-image>
                  </div>
                  <div class="right_content">
                    <div class="right_content_title">{{item.product_name}}</div>
                    <div class="flex SKU">
                      <div>
                        <span>SKU：</span>
                        <span>{{item.seller_sku}}</span>
                      </div>
                      <div>
                        <span>FNSKU：</span>
                        <span>{{item.fba_fnsku}}</span>
                      </div>
                    </div>
                    <div v-if="!!item.design_item_info">
                      <span>规格：</span>
                      <span>{{!!item.design_item_info ? item.design_item_info.color_name : ''}} / {{!!item.design_item_info ?item.design_item_info.size_name : ''}}</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="4">
                  <span v-if="!!item.product_unit_price">货款: ￥{{item.product_unit_price}} / 件</span>
                  <span v-else class="notAssociated">未关联产品</span>
                </el-col>
                <el-col :span="4">数量：{{item.fba_qty}}</el-col>
                <el-col :span="4">
                  <span v-if="!!item.product_unit_price && !!item.fba_qty">小计：￥{{getSum(item.product_unit_price,item.fba_qty)}}</span>
                  <span v-else class="notAssociated">未关联产品</span>
                </el-col>
              </el-row>

            </div>
            <div class="product_info_bottom">
              <span class="totalPayment">货款总额：</span><span>￥{{setMoney}}</span>
              <span class="color_text ml_20">产品货款：</span><span>￥{{fbaPlanPaginationItem.fba_payment_price}}</span>
              <span class="color_text ml_20"> FBA处理费：</span><span>￥{{fbaPlanPaginationItem.fba_process_fee}}</span>
              <span class="color_text ml_20" >FBA运费：</span>
              <span v-if="!!Number(fbaPlanPaginationItem.fba_shipping_price)">￥{{ fbaPlanPaginationItem.fba_shipping_price}}</span>
              <span v-else style="color: red">装箱后结算</span>
            </div>
          </div>

<!--          收货地址-->
          <div class="receiving_address_box">
            <div class="receiving_address_top">
              <div class="shippingAddress">收货地址</div>
              <div class="logistics">物流信息</div>
            </div>
            <div class="contentBox">
              <div class="receiving_address_content">
                <div class="flex">
                  <div class="name">收货人：</div>
                  <div  class="content">{{fbaPlanPaginationItem.fba_address_name}}</div>
                </div>
                <div class="flex">
                  <div class="name">国家：</div>
                  <div class="content">{{setState(fbaPlanPaginationItem.fba_address_country)}}</div>
                </div>
                <div class="flex">
                  <div class="name">省 / 州：</div>
                  <div class="content">{{fbaPlanPaginationItem.fba_address_state}}</div>
                </div>
                <div class="flex">
                  <div class="name">邮编：</div>
                  <div class="content">{{fbaPlanPaginationItem.fba_address_postcode}}</div>
                </div>
                <div class="flex">
                  <div class="name">城市：</div>
                  <div class="content">{{fbaPlanPaginationItem.fba_address_city}}</div>
                </div>
                <!--                <div class="flex">-->
                <!--                  <div class="name">电话：</div>-->
                <!--                  <div class="content">111111</div>-->
                <!--                </div>-->
                <div class="flex">
                  <div class="name">地址：</div>
                  <div class="content">{{fbaPlanPaginationItem.fba_address_line}}</div>
                </div>
                <div class="flex">
                  <div class="name">手机：</div>
                  <div class="content">{{fbaPlanPaginationItem.fba_address_phone}}</div>
                </div>
                <!--              <div class="flex">-->
                <!--                <div class="name">邮箱：</div>-->
                <!--                <div class="content">33333 LBJ FWY,Dallas,TX,US</div>-->
                <!--              </div>-->
                <div class="flex">
                  <div class="name">仓库编码：</div>
                  <div class="content">{{fbaPlanPaginationItem.fba_name}}</div>

                </div>
              </div>
              <div class="logisticsInformation">
                <div class="flex">
                  <div class="name">物流名称：</div>
                  <div  class="content">{{fbaPlanPaginationItem.ship_info ? fbaPlanPaginationItem.ship_info.name : '未选择物流'}}</div>
                </div>
                <div class="flex">
                  <div class="name">时效：</div>
                  <div  class="content">3-8工作日</div>
                </div>
              </div>
            </div>
          </div>

          <!--          region 报关信息-->
          <!--          <div class="declareCustoms">-->
          <!--            <div class="declareCustoms_top">-->
          <!--              报关信息-->
          <!--            </div>-->
          <!--            <el-table-->
          <!--                :data="tableData"-->
          <!--                height="350"-->
          <!--                border-->
          <!--                style="width: 100%">-->
          <!--              <el-table-column-->
          <!--                  prop="date"-->
          <!--                  label="中文名称"-->
          <!--                  width="100"-->
          <!--                 >-->
          <!--              </el-table-column>-->
          <!--              <el-table-column-->
          <!--                  prop="name"-->
          <!--                  label="英文名称"-->
          <!--                  width="100"-->
          <!--                  >-->
          <!--              </el-table-column>-->
          <!--              <el-table-column-->
          <!--                  prop="address"-->
          <!--                  width="150"-->
          <!--                  label="产品材质(英文)">-->
          <!--              </el-table-column>-->
          <!--              <el-table-column-->
          <!--                  prop="address"-->
          <!--                  width="150"-->
          <!--                  label="申报价格（USD）">-->
          <!--              </el-table-column>-->
          <!--              <el-table-column-->
          <!--                  prop="address"-->
          <!--                  width="150"-->
          <!--                  label="申报重量(g)">-->
          <!--              </el-table-column>-->
          <!--              <el-table-column-->
          <!--                  prop="address"-->
          <!--                  width="100"-->
          <!--                  label="申报数量">-->
          <!--              </el-table-column>-->
          <!--              <el-table-column-->
          <!--                  prop="address"-->
          <!--                  label="海关编码">-->
          <!--              </el-table-column>-->
          <!--            </el-table>-->
          <!--            <div class="declareCustoms_bottom">-->
          <!--              订单申报总价：473.23 USD-->
          <!--            </div>-->
          <!--       endregion  </div>-->

          <!--   region   物流信息-->
<!--          <div class="logisticsInfo">-->
<!--            <div class="logisticsInfo_top">物流信息</div>-->
<!--            <el-table-->
<!--                :data="tableList"-->
<!--                border-->
<!--                style="width: 100%">-->
<!--              <el-table-column-->
<!--                  prop="date"-->
<!--                  label="物流方式"-->
<!--              >-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="name"-->
<!--                  label="物流单号"-->
<!--              >-->
<!--              </el-table-column>-->
<!--              <el-table-column-->
<!--                  prop="address"-->
<!--                  label="发货时间">-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--        endregion  </div>-->

          <!--        订单动态-->
          <div class="order_status">
            <div class="order_status_top">订单动态</div>
            <div class="order_box">
              <el-steps direction="vertical"  :active="1" space="100px" align-center style="flex: 1" >
                <el-step icon="el-icon-location" class="el_step" v-for="(item,index) in fbaPlanPaginationItem.plan_process" :key="index">
                  <div slot="title" class="title">{{item.time}}</div>
                  <div slot="description">
                    <div class="description_title">{{item.title}}</div>
                    <div>{{item.describe}}</div>
                  </div>
                </el-step>
              </el-steps>
            </div>
          </div>
        </div>

<!--      <span slot="footer" class="dialog-footer" v-show="fbaPlanPaginationItem.status === 0">-->
<!--        <el-button @click="handleClose">取 消</el-button>-->
<!--        <el-button type="primary" @click="confirm">确 定</el-button>-->
<!--      </span>-->
    </el-dialog>
  </div>
</template>

<script>
import noimage from "@/assets/noimage.png";
import BigNumber from "bignumber.js";
// import {confirmEdit} from "@/api/order";

export default {
  name: "orderInformation",
  props:{
    value:{
      type:Boolean,
      default:false
    },
    fbaPlanPaginationItem:{
      type:Object,
      default: ()=>({})
    },
    orderListStatus:{
      type:Array,
      default:()=>([])
    },
    siteList:{
      type:Array,
      default:()=>([])
    },
    fbaId:{
      type:Number,
      default:null
    },
    shopList:{
      type:Array,
      default:()=>([])
    }
  },
  data: () => ({
    noimage,
    tableData: [
        {
      date: '2016-05-03',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1518 弄'
    }, {
      date: '2016-05-02',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1518 弄'
    }, {
      date: '2016-05-04',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1518 弄'
    }, {
      date: '2016-05-01',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1518 弄'
    }, {
      date: '2016-05-08',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1518 弄'
    }, {
      date: '2016-05-06',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1518 弄'
    }, {
      date: '2016-05-07',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1518 弄'
    }
    ],
    tableList: [
        {
      date: '2016-05-02',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1518 弄'
    }, {
      date: '2016-05-04',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1517 弄'
    }, {
      date: '2016-05-01',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1519 弄'
    }, {
      date: '2016-05-03',
      name: '王小虎',
      address: '上海市普陀区金沙江路 1516 弄'
    }
    ]
  }),
  mounted() {

  },
  methods: {
    handleClose(){
      this.$emit('input',false)
    },
    // async confirm(){
    //   try {
    //     const { msg } = await confirmEdit({
    //       fbaId:this.fbaId
    //     })
    //     this.$message.success(msg)
    //     this.handleClose()
    //     this.$emit('update')
    //   }catch (e) {
    //     this.$message.error(e?.msg)
    //   }
    // }
  },
  watch: {},
  computed:{
    //订单状态
    shopState(){
      return (val)=>{
        let title
        this.orderListStatus.forEach(item=>{
          if(item.id === val) {
            title = item.title
          }
        })
        return title
      }
    },
    //小计
    getSum(){
     return (val,item)=>{
       if(!!val && !!item) {
         return  new BigNumber(val).times(new BigNumber(item)).toFixed(2)
       }

      }
    },
    //国家
    setState(){
      return (val)=>{
        let name
        this.siteList.forEach(item=>{
          if(item.country_code === val) {
            name = item.name_zh
          }
        })
        return name
      }
    },
    //店铺
    setStore(){
      return (val)=>{
        let name
        this.shopList.forEach(item=>{
          if(item.code === val) {
            name = item.name
          }
        })
        return name
      }
    },
    //总余额
    setMoney(){
      //**
      //产品货款      fba_payment_price
      // fba处理费   fba_process_fee
      //fba运费     fba_shipping_price
      // 产品货款fba_payment_price + fba处理费fba_process_fee + fba运费fba_shipping_price
      return (Number(this.fbaPlanPaginationItem.fba_payment_price) + Number(this.fbaPlanPaginationItem.fba_process_fee) + Number(this.fbaPlanPaginationItem.fba_shipping_price)).toFixed(2)
    }
  }
}
</script>

<style scoped lang="less">
.notAssociated {
  color: red;
}
.color_text {
  color: grey;
}
.ml_20 {
  margin-left: 30px;
}
/deep/.el-dialog {
  margin-top: 5vh!important;
}
/deep/.el-dialog__header {
  border-bottom: 1px solid #eee;
}
/deep/.el-dialog__body {
  padding: 10px 20px;

}
.flex {
  display: flex;
}
.orderInfo {
  height: 600px;
  overflow: auto;
}
//<!--      头部信息-->
.order_info_top {
  line-height: 40px;
  background: #eee;
  justify-content: space-between;
  padding-left: 10px;

}
//<!--      产品信息-->
.product_info_box {
  border: 1px solid #EBEEF5;
  margin-top: 10px;
  .product_info_top {
    font-weight: 600;
    border-bottom: 1px solid #EBEEF5;
    line-height: 40px;
    padding-left: 10px;
  }
  .product_info_content {
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #EBEEF5;
    .img_box {
      width: 80px;
      height: 80px;
    }
    .right_content {
      flex: 1;
      margin-left: 10px;
      .SKU {
        justify-content: space-between;
      }
    }
    .right_content_title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    }
  }
  .product_info_bottom {
    padding: 10px;
    .totalPayment{
      font-weight: 600;
    }
  }
}
//<!--          收货地址-->
.receiving_address_box {
  border: 1px solid #EBEEF5;
  margin-top: 10px;
  .receiving_address_top {
    display: flex;
    font-weight: 600;
    border-bottom: 1px solid #EBEEF5;
    line-height: 40px;
    padding-left: 10px;

    .shippingAddress {
      width: 50%;
    }
    .logistics {
      flex: 1;
    }
  }
  .contentBox {
    display: flex;
    .receiving_address_content {
      width: 50%;
      padding: 30px;
      font-size: 14px;
      line-height: 25px;
      .name {
        width: 150px;
        display: flex;
        justify-content: flex-end;
        margin-right:25px ;
        color: #909399;
      }
      .content {
        color: black;
      }
    }
    .logisticsInformation {
      padding: 30px;
        flex: 1;
      line-height: 24px;
      .name {
        width: 150px;
        display: flex;
        justify-content: flex-end;
        margin-right:25px ;
        color: #909399;
      }
      .content {
        color: black;
      }
    .logisticsInformation {
      padding: 30px;
      flex: 1;
    }
    }
  }


}
//<!--          报关信息-->
 .declareCustoms {
  margin-top: 20px;
  .declareCustoms_top {
    border: 1px solid #EBEEF5;
    border-bottom: 0;
    font-weight: 600;
    line-height: 40px;
    padding-left: 10px;
  }
   .declareCustoms_bottom {
     border: 1px solid #EBEEF5;
     border-top: 0;
     font-weight: 600;
     line-height: 40px;
     padding-right:  10px;
     display: flex;
     justify-content: flex-end;
   }
}
//<!--      物流信息-->
.logisticsInfo {
  margin-top: 20px;
  .logisticsInfo_top {
    border: 1px solid #EBEEF5;
    border-bottom: 0;
    font-weight: 600;
    line-height: 40px;
    padding-left: 10px;
  }
}

.order_status {
  margin-top: 20px;
  border: 1px solid #EBEEF5;
  .order_status_top {
    border-bottom: 1px solid #EBEEF5;
    border-bottom: 0;
    font-weight: 600;
    line-height: 40px;
    padding-left: 10px;
  }
  .el_step {
    position: relative;

    padding-left: 180px;
    .title {
      position: absolute;
      left: 20px;
      font-size: 14px;
    }
    .description_title {
      font-weight: 600;
      font-size: 14px;
    }
  }
  .order_box {
    height: 200px;
    overflow: auto;
  }
}

::-webkit-scrollbar {
  width: 8px; /*对垂直流动条有效*/
  height: 20px; /*对水平流动条有效*/
  // background-color:red
}
/* 滚动槽（轨道）样式 */


/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
  border-radius: 7px;

  background-color: #E8E8E8;
}




// 滚动条的宽度
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
}
// 滚动条的滑块
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>